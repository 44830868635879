import React, { Component } from 'react';
import Icofont from "react-icofont";
import PropTypes from "prop-types";

export class Services extends Component {
  render() {
    //Start Services Loop
    const servicedata = this.props.servicesData.map((services, index ) =>(
        <div className="col-lg-3 col-md-6 col-sm-6" key={index}>
            <div className="single-services">
                <Icofont icon={services.icon} />
                <h3>{services.title}</h3>
                <p>{services.content}</p>

                <div className="bg-number">{services.bgnumber}</div>
            </div>
        </div>
    ));
    //End Services Loop
    return (
        <React.Fragment>
            <section id="services" className="services-area ptb-100">
                <div className="container">
                    <div className="section-title">
                        <h2>{this.props.sectionTitle} <b>{this.props.sectionTitleSpan}</b></h2>
                        <p>{this.props.sectionContent}</p>
                    </div>
                    <div className="row">{servicedata}</div>
                </div>
                <div className="animation-box5">
                    <img src={this.props.animationImg} className="rotateme" alt="vector" />
                </div>
            </section>
        </React.Fragment>
    );
  }
}
Services.propsTypes = {
    sectionTitle: PropTypes.string,
    sectionTitleSpan: PropTypes.string,
    sectionContent: PropTypes.string,
    animationImg: PropTypes.string,
    servicesData: PropTypes.array
}
Services.defaultProps = {
    sectionTitle: "Our",
    sectionTitleSpan: "Services",
    sectionContent: "We are providing following services ",
    animationImg: require("../../src/assets/img/vector-bg.png"),

    servicesData: [
        {
            icon: "icofont-business-man-alt-1",
            title: "Body Shopping ",
            content: "Do you have a team and you need highly qualified member to join your team? We can delivery to you our professional for Java Development, Front end Development, Testing, Analytics and Project management ",
            bgnumber: "1"
        },
        {
            icon: "icofont-network",
            title: "SysOps & DevOps",
            content: "Do you need setup, maintain production environment, speedup development process or setup continues delivery? We have proper know-how",
            bgnumber: "2"
        },
        {
            icon: "icofont-chart-growth",
            title: "Consulting",
            content: "Starting a project or need optimize current one? We can pick up the most efficient solution or architecture to fit your needs",
            bgnumber: "3"
        },
        {
            icon: "icofont-clouds",
            title: "Cloud",
            content: "We provide consulting and implementation on AWS cloud platform. We are your  partner to help you with moving to AWS cloud",
            bgnumber: "4"
        },
        {
            icon: "icofont-laptop-alt",
            title: "Web & Mobile Application",
            content: "Do you need mobile or web application? We can implement it for you by your requirements from scratch",
            bgnumber: "5"
        },
        {
            icon: "icofont-tick-boxed",
            title: "Information system development",
            content: "CRM, eCommerce sites ? We can develop full tailor-made information system for your needs",
            bgnumber: "6"
        },
        {
            icon: "black-board",
            title: "Lecture & Training",
            content: "Currently offering training and lecture for Java, Spring, Git",
            bgnumber: "7"
        },
        {
            icon: "icofont-telescope",
            title: "SEO Consulting",
            content: "Can not find your site on Google? We can help you with that. We provide basic SEO consulting and implementation",
            bgnumber: "8"
        }
    ]
}
export default Services


import React, { Component } from "react";
import Icofont from "react-icofont";
import PropTypes from "prop-types";

class Welcome extends Component {
    render() {
        //Welcome loop start
        const welcomedata = this.props.welcomesData.map((welcome, index) =>( 
            <div className="col-lg-4 col-md-6 col-sm-6" key={index}>
                <div className="single-box">
                    <Icofont icon={welcome.icon} />
                    <h3>{welcome.title}</h3>
                    <p>{welcome.content}</p>
                </div>
            </div>
            ));
        //Welcome loop END

        return (
            <React.Fragment>
                <section className="welcome-area ptb-100">
                    <div className="container">
                        <div className="section-title">
                            <span>{this.props.sectiontopTitle}</span>
                            <h2>{this.props.sectionTitle} <b>{this.props.sectionTitleSpan}</b></h2>
                            <p>{this.props.sectionContent}</p>
                        </div>

                        <div className="row">
                            {welcomedata}
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

Welcome.propTypes = {
    sectiontopTitle: PropTypes.string,
    sectionTitle: PropTypes.string,
    sectionTitleSpan: PropTypes.string,
    sectionContent: PropTypes.string,
    welcomesData: PropTypes.array
};

Welcome.defaultProps = {
    sectiontopTitle: "WE ARE CREATIVE",
    sectionTitle: "Welcome to",
    sectionTitleSpan: "Noxtr corporation s.r.o",
    sectionContent:
        "Czech Software Development and IT sourcing company with huge experience ",

        welcomesData: [
        {
            icon: "icofont-laptop-alt",
            title: "Web & Mobile application",
            content:
                "Full responsive web and Native mobile application to fit your needs"
        },
        {
            icon: "icofont-business-man-alt-1",
            title: "IT sourcing",
            content:
                "IT professionals for your team. Java Development, Front end Development, Testing, Analytics and Project management"
        },
        {
            icon: "icofont-gears",
            title: "Information system",
            content:
                "Tailor-made custom Information System for your needs"
        }

    ]
};
export default Welcome;
